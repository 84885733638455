<template>
    <v-app>
        <div class="frame-popup">
            <header>
                <h1>SMS 발송</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main><E030101 ref="sms" /></main>
        </div>
        <v-dialog v-model="progress_bar" content-class="jh-prograss-bar" elevation="10">
            <v-progress-circular
                :size="60"
                :width="6"
                color="secondary"
                indeterminate
            ></v-progress-circular>
        </v-dialog>
    </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import E030101 from "./E030101.vue";

export default {
    name: "MENU_E030101P01", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {},
    components: {
        E030101
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            progress_bar: "commonStore/GE_COMMON_PROGRESS_BAR",
        })
    },
    methods: {
        close() {
            self.close();
        },
        doNotReload: function(e) {
            if (e.keyCode == 116 || (e.ctrlKey && (e.keyCode == 78 || e.keyCode == 82))) {
                return false;
            }
        }
    },
    created() {
        // 새로고침 방지
        self.onkeydown = this.doNotReload;
        self.oncontextmenu = () => { return false };
    },
    mounted() {

        let ctr = this.$route.query.ctr
        
        console.log("ctr   ", ctr)
        
        // 토큰 동기화를 위해 메인창에 window 객체에 팝업 windows 객체 저장
        // window 팝업에서 palette api를 호출할 경우 필수
        if (opener) {
            if (!opener.popups) opener.popups = [];
                opener.popups.push(self);
        }
        // 상담메인의 SMS 발송 버튼 클릭 시 고객번호를 받기 위한 로직, 팝업 화면이 잘 로딩됐음을 메인창에 알리는 용도
        // opener(메인창의 window객체)의 smsPopup에 this.$refs.sms를 할당하여 상담메인에서 SMS 발송 화면의 함수를 호출할 수 있게 함
        if (opener && opener.location.pathname == "/E010101" && opener.onmessage) {
            opener.smsPopup = this.$refs.sms;
            opener.postMessage(JSON.stringify({ evt: "smsPopupLoaded" }), "*");
        }
    },
    watch: {}
};
</script>

<style>
    .jh-container{width: 100% !important;}
</style>